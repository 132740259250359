import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js';

Vue.use(Vuex)

/*
My Naming Conventions:
state objects/arrays/primites are PascalCase (except booleans)
state arrays are pluralised
state objects are singular
state object properties are PascalCase (because the database field names are PascalCase and it requires less translation)
state booleans start with 'is' but after that are PascalCase
anything to do with application state (visuals not connected to data state) are in the sub-object state.appllication
Mutations are SHOUTY_SNAKE_CASE
Actions are PascalCase and start with a verb
variables in actions are camelCase
payloads are the entity name in lowercase or 'payload' if mixed/limited entity fields
values sent to POST API routes are camelCase

*/
export default new Vuex.Store({
  state: {
    ActiveEvent: {},
    ActiveUser: {},
    isUserLoggedIn: false,
    FBGroups: [],
    Trails: [],
    Events: [],
    // Google_map_ID: '',
    application: {
    }
  },
  mutations: {
    SET_ACTIVE_EVENT(state, event) {
      state.ActiveEvent = event
    },
    SET_PARTICIPANT_FOR_EVENT(state, user) {
      state.ActiveUser = user
    },
    // SET_PARTICIPANT_BY_EMAIL (state, user) {
    //   state.ActiveUser = user
    // },
    SET_USER_LOGGED_IN(state, isLoggedIn) {
      state.isUserLoggedIn = isLoggedIn
    },
    SET_NEXT_EVENTS(state, events) {
      state.Events = events
    },
    RESET_ACTIVE_USER(state) {
      state.ActiveUser = {}
      state.isUserLoggedIn = false
    },
    RESET_ACTIVE_EVENT(state) {
      state.ActiveEvent = {}
    },
    SET_FACEBOOK_GROUPS(state, groups) {
      state.FBGroups = groups
    },
    // To be checked...
    async GET_MAP_ID(state) {
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}GetGoogleMapID`
      const response = await axios.get(url)
      state.Google_map_ID = response.data
    }
  },
  actions: {
    async FetchEventBySlug({ commit }, slug) {
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}TrailBySlug?slug=${slug}`
      const response = await axios.get(url)
      const event = response.data[0]
      console.log('event pinPostCode: ', event.PinPostCode)

      // get the lat long for the trail
      const urlCoords = `${baseUrl}getLatLongFromPostcode`
      const responseCoords = await axios.post(
        urlCoords,
        {
          Postcode: event.PinPostCode
        })
      const coords = await responseCoords.data[0]
      console.log('event: ', event)
      console.log('coords: ', coords)
      
      //Get KMLs for Event
      const urlKML = `${baseUrl}getKMLsByEventID`
      const responseKML = await axios.post(
        urlKML,{
          EventID: event.ID
        }
      )
      const KMLShapes = await responseKML.data
      console.log(KMLShapes)

      //TODO: get price and threshold from event details
      const calcPrice = event.Sellers > 10 ? 4.99 : 0.99
      const eventPlus = await {
        ...event,
        ...{ lat: coords.latitude, long: coords.longitude },
        ...{ KMLShapes: KMLShapes },
        ...{ price: calcPrice }
      }
      console.log('EventPlus: ', eventPlus)
      commit('SET_ACTIVE_EVENT', eventPlus)
    },
    async CheckParticipantByEmail({ commit }, payload) {
      const baseUrl = process.env.VUE_APP_API_URL
      const urlEmail = `${baseUrl}getParticipantbyEmail`
      const { emailAddress, eventID } = payload
      const response = await axios.post(
        urlEmail,
        {
          EmailAddress: emailAddress
        }
      )
      const user = response.data[0]
      const participantID = user.ParticipantID
      commit('SET_PARTICIPANT_FOR_EVENT', user)
      if (user.ParticipantID > 0) {
        const urlEventParticipant = `${baseUrl}getParticipantForEvent`
        const payloadEventParticipant = {
          participantID,
          eventID
        }
        const responseEventParticipant = await axios.post(
          urlEventParticipant,
          payloadEventParticipant
        )
        const user = responseEventParticipant.data[0]
        /* If nothing comes back then we know the participant but not for this event */
        if (user) {
          commit('SET_PARTICIPANT_FOR_EVENT', user)
        }
      }
    },
    async CheckPasscode({ commit }, payload) { // payload = {participantID, passcode}
      let isLoggedIn = false
      const passcode = payload.passcode
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}CheckPasscode`
      const response = await axios.post(
        url,
        payload // {participantID,passcode}
      )
      const passcodeChecked = await response.data[0] // Passcode or empty record set
      if (passcodeChecked !== 'undefined') {
        if (passcodeChecked.LoginCode === passcode) {
          const user = { ...this.state.ActiveUser, Passcode: passcode }
          commit('SET_PARTICIPANT_FOR_EVENT', user)
          isLoggedIn = true
        }
        commit('SET_USER_LOGGED_IN', isLoggedIn)
        return isLoggedIn
      }
    },
    async GetNextEvents({ commit }) {
      console.log('GetNextEvents called')
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}getNextEvents`
      const response = await axios.get(url)
      const events = response.data
      commit('SET_NEXT_EVENTS', events)
    },
    ResetActiveUser({ commit }) {
      commit('RESET_ACTIVE_USER')
    },
    ResetActiveEvent({ commit }) {
      commit('RESET_ACTIVE_EVENT')
    },
    // GetParticipantForEvent ({ commit }, { ParticipantID, EventID }) {
    //   commit('GET_PARTICIPANT_FOR_EVENT', { ParticipantID, EventID })
    // },
    // Being Checked...
    async EmailRequestPasscode({ commit }, payload) {
      // get the passcode and then send the email
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}GetPasscode`
      const response = await axios.post(
        url,
        payload // {participantID}
      )
      console.log('EmailRequestPasscode: ', response.data[0])
      const Passcode = await response.data[0] // Passcode and EmailAddress or empty record set
      if (Passcode !== 'undefined') {
        // we got the email and passcode
        const emailURL = `${baseUrl}EmailRequestPasscode`
        const emailSent = await axios.post(
          emailURL,
          {
            EmailFrom: 'info@JubleTrail.uk',
            EmailTo: Passcode.EmailAddress,
            Subject: 'Passcode reminder - JumbleTrail UK',
            Message: 'Here is the reminder of your passcode to log into JumbleTrail.uk: ' + Passcode.Passcode
          }
        )
        console.log('emailSent: ', emailSent)
        return emailSent
      }
      return 'Email Not Sent'
    },
    async RegisterSeller({ commit }, payload) {
      console.log('in RegisterSeller:', payload)
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}addEventParticipant`
      console.log('url:', url)
      const response = await axios.post(
        url,
        payload
      )
      /* 
      The data that comes back contains many results sets due to the way the stored procedure works.
      We want the last results set
      */
      console.warn('RegisterSeller: ', response.data[response.data.length - 1])
      return response.data[response.data.length - 1]
    },
    async RegisterBuyer({ commit }, payload) {
      console.log('In RegisterBuyer: ', payload)
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}addEventParticipant`
      console.log('url', url)
      const response = await axios.post(
        url,
        payload
      )
      return response
    },
    async LaunchCheckout({ commit }, payload) {
      // console.log("Stripe Key:", process.env.VUE_APP_stripe_api_key)
      const stripe = await loadStripe(process.env.VUE_APP_stripe_api_key)
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}createCheckoutSession`
      axios.post(
        // 'http://localhost:7071/api/createCheckoutSession',
        url,
        payload
      )
        .then((res) => {
          // console.log("res.data:",res.data)
          return res.data
        })
        .then((session) => {
          // console.log("session ID :", session.id)
          // const sessionID = session.id
          // console.log("stripe", stripe)
          return stripe.redirectToCheckout({ sessionId: session.id })
        })
        .then((res) => {
          if (res.error) {
            // alert(res.error.message)
          }
        })
        .catch((err) => {
          console.error('Error: ', err)
        })
    },
    async FetchFacebookGroups({ commit }) {
      console.log('FetchFacebookGroups called')
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}getFacebookGroups`
      const response = await axios.get(url)
      const groups = response.data
      commit('SET_FACEBOOK_GROUPS', groups)
    },
    // To be checked...
    GetMapId({ commit }) {
      commit('GET_MAP_ID')
    },

    ChargeCreditCard({ commit }, data) {
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}chargeCreditCard`
      axios.post(
        // 'https://jumbletrailuk.azurewebsites.net/api/chargeCreditCard',
        // 'http://localhost:7071/api/chargeCreditCard',
        url,
        data
      ).then(res => {
        if (res.status === 200) {
          alert('Transaction Succeeded')
          self.lockSubmit = false
        } else {
          throw new Error('Transacton failed')
        }
      }).catch((err) => {
        alert('Error: ', err.message)
        self.lockSubmit = false
      })
    }
  },
  getters: {
    getActiveUser(state) {
      return state.ActiveUser
    },
    // To Be Checked . . .
    getMapID(state) {
      return state.ActiveEvent.Google_map_ID
    },
    getEvents(state) {
      // return [{ EventID: 123 }, { EventID: 456 }]// this.state.Events
      return state.Events.sort((a,b) => (a.EventDateRaw > b.EventDateRaw) ? 1:-1 )
    },
    getActiveEvent(state) {
      return state.ActiveEvent
    },
    getGroups(state) {
      return state.FBGroups
    },
    getScratch() {
      return 6
    }
  },
  modules: {
  }
})
