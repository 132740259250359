<template>
  <b-link :to="EventLink(Event)">
    <b-card
      style="min-width: 150px; max-width: 300px; width: 30vw"
      class="text-center mb-2"
      :bg-variant="idx % 2 == 1 ? 'primary' : 'info'"
      :text-variant="idx % 2 == 0 ? 'primary' : 'info'"
    >
      <b-card-body>
        <b-card-title style="height: 5vh"> {{ Event.EventName }}</b-card-title>
        <b-card-header> {{ Event.EventDate }}</b-card-header>
      </b-card-body>
    </b-card>
  </b-link>
</template>

<script>
export default {
  name: "EventListItem",
  props: {
    Event: Object,
    idx: Number,
  },
  methods: {
    EventLink(Event) {
      return "Event/" + Event.EventSlug;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
