<template>
  <div class="hello">
     <EventList />
  </div>
</template>

<script>
import EventList from '@/components/EventList.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'HelloWorld',
  components: {
    EventList
  },
  computed: {
    ...mapGetters(['getEvents', 'getMapID']), /* not currently using Google Maps... */
    ...mapState(['Events', 'Google_map_ID']) /* not currently using Google Maps... */
  },
  methods: {
    ...mapActions(['GetNextEvents', 'GetMapId']) /* not currently using Google Maps... */
  },
  async created () {
    if (this.Events.length === 0) {
      await this.GetNextEvents()
    }
  },
  mounted () {
  }
}
</script>
