<template>
<div>
   <div class="col">
          <h1>Jumble:</h1>
          <p>
            an odd assorment of unconnected things
          </p>
          <h1>Trail:</h1>
          <p>
            a path or route
          </p>
        </div>
        <div class="row">
          <div class="col">
            <h4>So what exactly is a Jumble Trail?</h4>
            <p>
              Well its a cross between a Car Boot sale and a Treasure Hunt!. The
              neighbourhood gets together and everyone who wants to, throws a
              garage sale / house clearance on the same day; you get to travel
              around the area and meet all these lovely people, grab some
              bargains and maybe discover some new favourite places you didn’t
              know about in your locality.
            </p>
            <p>
              We help to organise, coordinate and promote trails on behalf of
              local communities, bring local people closer together (but still
              2m apart…), provide fun-filled days for both buyers and sellers
              and perhaps most importantly, keep useable items out of landfill.
              <br />
              Sellers register on the site and we provide a map with all of the
              registered locations. You can download the map and list of seller
              addresses as a PDF (mobile app coming soon!) Each sale also has a
              facebook group where we can collect stories and photos of the day.
              <br />
              We organise events for most weekends from Spring to Autumn and our
              ambition is to enable every postcode in the UK to have at least
              one chance of being involved every year.
            </p>

            <p></p>
            <h5>Trail Hosts:</h5>
            <p>
              As a seller (Trail Host), we think that a Jumble Trail is
              <strong>waaaaay</strong> better than a car boot sale. No packing
              the car the night before, no getting up at silly-o-clock in the
              morning, take it in shifts to man the stall and if it doesn’t sell
              then you can leave it out for the charity vans or just put it back
              where it came from.
            </p>
            <h5>Trail Hunters:</h5>
            <p>
              Likewise as a buyer (Trail Hunter) we love them too. You get a wider range of
              goods as most people are limited to what they can take to a car
              boot, happier sellers because they had a good nights sleep and
              less pressure to get there early to ‘get the good stuff’ because
              all buyers will start at a different place on the map.
            </p>
          </div>
        </div>
</div>

</template>

<script>
export default {
  name: 'HomePageBlurb'
}
</script>
