<template>
<div >
  <EventRegistration></EventRegistration>
</div>
</template>

<script>
import EventRegistration from '@/components/EventRegistration.vue'

export default {
  name: 'EventDetail',
  components: {
    EventRegistration
  }
}
</script>

