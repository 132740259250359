<template>
  <b-container v-cloak>
    <EventRegistrationBlurb
    :ActiveEvent = "ActiveEvent"
    ></EventRegistrationBlurb>
   
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import EventRegistrationBlurb from '@/components/EventRegistrationBlurb.vue'
import EventRegistrationForm from '@/components//EventRegistrationForm.vue'
// import EventMap from '@/components/EventMap.vue'

export default {
  name: 'EventDetail',
  components: {
    EventRegistrationBlurb,
    EventRegistrationForm,
    // EventMap
  },
  data () {
    return {
      registrationType: null,
      passcodeValidated: false,
      form: {
        emailAddress: '',
        address1: '',
        address2: '',
        address3: '',
        town: '',
        county: '',
        postCode: '',
        participantType: null,
        tellMe: '',
        distance: 20
      }
    }
  },
  methods: {
    // ...mapGetters(['getActiveEvent']),
    ...mapActions(['FetchEventBySlug', 'ResetActiveUser', 'ResetActiveEvent', 'CheckPasscode']),
    forgotPasscode () {
      return null
    },
    formatDate (inDate) {
      const MyDate = Date.parse(inDate)
      return Date.Year(MyDate)
    },
    subscriptionCode () {
      const validString = 'ABCDEFGHJLKNPRSTUVWXY'
      var returnString = ''
      var index = 0
      while (returnString.length < 5) {
        index = [parseInt(validString.length * Math.random())]
        returnString = returnString + validString[index]
      }
      return returnString
    },
    buyerOrSeller () {
      if (this.form.participantType === 'Seller') {
        this.launchCheckout()
      } else {
        this.registerBuyer()
      }
    },
    clickForm () {
      alert('Form Clicked!')
    },
    checkForEmailRegistration () {
      // pass the event Id and email address to the API and see if we get a match
      dispatchEvent('CheckParticipantByEmail')
    },
    async validatePasscode () {
      this.passcodeValidated = dispatchEvent('CheckPasscode', { ParticipantID: this.ActiveUser.ParticipantID, Passcode: this.form.Passcode })
    },
    login () {
      // set login cookie and redirect to user administration
    },
    registerBuyer () {
      axios.post('http://localhost:7071/api/addEventParticipant', {
        eventID: this.ActiveEvent.ID,
        eventName: this.ActiveEvent.TrailName,
        participantType: this.form.participantType,
        subscriptionCode: this.subscriptionCode(),
        price: 7.99,//this.getPrice,
        emailAddress: this.form.emailAddress,
        address1: this.form.address1,
        address2: this.form.address2,
        address3: this.form.address3,
        town: this.form.town,
        county: this.form.county,
        postCode: this.form.postCode,
        tellMe: this.form.tellMe,
        distance: this.form.distance
      })
        .then((res) => {
          console.log(res.data[0])
        })
        .then((sc) => {
          window.location.href = 'http://192.168.1.35:8080/#/Register/?' + this.form.subscriptionCode
        })
    },
    launchCheckout () {
      const baseUrl = process.env.VUE_APP_API_URL
      const url = `${baseUrl}createCheckoutSession`
      axios.post(
      // 'http://localhost:7071/api/createCheckoutSession',
        url, {
          eventID: this.ActiveEvent.ID,
          eventName: this.ActiveEvent.TrailName,
          participantType: this.form.participantType,
          subscriptionCode: this.subscriptionCode(),
          price: 7.99,//this.getPrice,
          emailAddress: this.form.emailAddress,
          address1: this.form.address1,
          address2: this.form.address2,
          address3: this.form.address3,
          town: this.form.town,
          county: this.form.county,
          postCode: this.form.postCode,
          tellMe: this.form.tellMe,
          distance: this.form.distance
        })
        .then((res) => {
          console.log(res.data)
          return res.data
        })
        .then((session) => {
          this.stripe.redirectToCheckout({ sessionId: session.id })
        })
        .then((res) => {
          if (res.error) {
            alert(res.error.message)
          }
        })
        .catch((err) => {
          console.error('Error: ', err)
        })
    }
  },
  computed: {
    ...mapState(['ActiveEvent']),
    facebookLink () {
      return `https://facebook.com/events/${this.ActiveEvent.FacebookEventNumber}`
    },
    getEventName () {
      return this.ActiveEvent.TrailName
    },
    getButtonText () {
      const buttonText = {
        Buyer: 'Register as a Buyer',
        Seller: 'Register and go to Checkout',
        Prospect: 'Register your Interest'
      }
      return buttonText[this.form.participantType] ?? 'Please choose Buyer or Seller'
    },
    getPrice () {
      console.log('in getPrice', this.ActiveEvent.Sellers)
      const price = this.ActiveEvent.Sellers > 10 ? 4.99 : 0.99
      return price
    },
    isFormValid () {
      // return this.isValidPostcode
      return this.checkValidPostcode
    },
    formStep () {
      if (this.form.distance < 20) return 1
      if (this.form.distance < 50) return 5
      if (this.form.distance < 100) return 10
      if (this.form.distance < 500) return 25
      return 1
    },
    checkValidPostcode () {
      const validPostcodes = this.ActiveEvent.ValidPostcodes?.map(pc => pc.PostCode.replace(' ', '').toUpperCase()) ?? ['not defined']
      const MatchedPostcodes = validPostcodes?.filter(pc => pc === this.form.postCode.toUpperCase().replace(' ', '').substr(0, pc.length)) ?? []
      if (this.form.participantType === 'Seller' || this.form.participantType === 'Prospect') return (MatchedPostcodes.length > 0 && this.form.postCode.length > 0)
      // console.log('checkValidPostcode = ', validPostcodes)
      return true
    },
    validPostcodes () {
      return this.ActiveEvent.ValidPostcodes?.map(pc => pc.PostCode).join(', ') ?? ''
    }
  },
  async created () {
    const slug = this.$route.params.slug
    await this.FetchEventBySlug(slug)
    console.log('we are created', this.$store.state.ActiveEvent.TrailName)
  },
  mounted () {
    window.scrollTo(0,0)
  },
  beforeDestroy () {
    // const self = this
    // self.Stripe.destroy()
    this.ResetActiveUser()
    this.ResetActiveEvent()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  [v-cloak] > * { display:none }
  [v-cloak]::before { content: "loading…" }
  .expandable {
    background: rgb(167, 23, 23);
    overflow: hidden;
    color: rgb(47, 206, 33);
    line-height: 50px;
    transition: all 5s ease-in-out;
    height: 0;
  }
  .hidden {
    display:none
  }
/*
 .container{
  box-sizing: border-box;
  background-color:rgba(224, 145, 145,50%);
  margin: 5%;
  padding: 3%;
  color: white;
  display:flex;
  flex-direction: column;
  align-content: center;
  align-items: left;
  height: calc(1000% - 10px);
  border-radius: 20px;
  box-shadow: 15px 15px 10px rgba(125,80,200,80%) ;
}
p,h1,h2,h3 {
  color:white;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}*/
/* form styling */
/* input {
  margin: 10px 10px;
  padding: 10px 0;
  border: 1px white solid;
  border-radius: 20px;
  box-shadow: 15px 15px 5px rgba(125,80,200,50%) ;
}
input[type="text"] ,input[type="email"] {
  width: 50%;
  min-width: 100px;
  display: block;
  box-sizing: border-box;
  padding: 10px 15px;
  font-size: 1.2em;
}
input:focus {
  appearance: none;
  outline:none;
  opacity: 80%;
}
label {
  font-size: 1.5em;
  width: 20%
}

button {
  background-color: purple;
  color: white;
  max-width: 50vw;
  min-width: 100px;
  height: 40px;
  border-radius: 20px;
  align-content: center;
  text-align: center;
  font-size: 1.5em;

} */
/* form {
  display: block;
} */
/*fieldset{
  border:none;
} */
</style>
