<template>
  <b-container>
    <b-row v-show="ActiveEvent.TrailName == undefined ? false : true">
      <b-col cols="12">
        <b-jumbotron>
        <h1 class="text-primary text-center">{{ ActiveEvent.TrailName }} Jumble Trail</h1>
        <h2 class="text-info text-center">
          {{
            [ActiveEvent.EventDate, "YYYY-MM-DD"] | moment("dddd, Do MMMM YYYY")
          }}
        </h2>
        </b-jumbotron>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="12">
        <!-- map -->
        <EventMap></EventMap>
      </b-col>
      <!-- <b-col cols="12" lg="6">
        One man's trash is another mans treasure!, Are you up for the challenge?
        We are organising a trail around the streets of
        {{ ActiveEvent.TrailName }}. A Jumble trail is a bit like a car boot
        except that we think it is way more fun. Rather than meet up in a muddy
        field, everyone gets to display their items on their own front garden.
        It is one MASSIVE co-ordinated garage sale event.
      </b-col> -->
    </b-row>
    <b-row>
      <b-col>
        <h3 class="text-info text-center">Join in!</h3>
        <!-- <p>
          It is free to register as a Hunter and just £4.99 to record your
          address as a Host on the trail map.
          <span v-if="ActiveEvent.Sellers < 10">
            To get the ball rolling, the first 10 Hosts get to register for just
            99p!
          </span>
          So far we have the following number of Hunters and Hosts.
        </p> -->
        <b-card bg-variant="light">
          <b-row>
            <b-col cols="6"
              ><div class="buyers">
                Hunters: {{ ActiveEvent.Buyers }}
              </div></b-col
            >
            <b-col cols="6"
              ><div class="buyers">Hosts: {{ ActiveEvent.Sellers }}</div></b-col
            >
          </b-row>
          <!-- <div class="d-flex flex-row justify-content-evenly align-items-center"> -->
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <EventRegistrationForm></EventRegistrationForm>
    </b-row>
    <b-row>
      <b-col>
        <h3>Its all about community</h3>
        <p>
          Our trails are community driven events with an emphasis on
          re-use/re-purpose/re-cycle. The main aims are
        </p>
        <ul>
          <li>Meet new people</li>
          <li>See parts of your local area you may not have visited before</li>
          <li>Keep useable items out of landfill</li>
          <li>Find hidden treasure</li>
          <li>Make a few pennies</li>
        </ul>
        <p>
          They work best when lots of local people get involved so we really
          encourage you to spread the word. If you have not already joined the
          facebook group, you can do it <a :href="facebookLink">here...</a>.
          This is where we post information relevant to the event and you can
          download the maps and rules (sorry, we have rules :( ) and keep up to
          date any news about this trail and any others we are organising. If
          you have twitter, snapchat or any of the other popular social
          networking apps, it really helps us if you can post about the event on
          those platforms too. We don't have the ability to drop mailers through
          doors or put up posters, but if you want to help out with these we are
          more than happy to send them out to you.
        </p>
        <p>
          Trails are unfortunately weather dependent, although we will persevere
          with a little bit of rain, it is inevitable that occassionally we have
          to call off a trail if the rain is going to be bad. Normally we set
          the limit at 12mm or more on the forecast available on the Thursday
          evening. In the event of a cancellation we will refund all fees paid
          by Trail Sites.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>Be a Trail Hunter</h3>
        <p>
          A trail would be nothing without the Trail Hunters and it is free to
          register as Hunter. A couple of days before the event we will make a
          map available for download on the facebook page, or if you register
          using the form below, we will send you a copy via email. Pick your own
          route around the stops and see how many stalls you can visit. You
          don't have to sign up to get the map but doing so also helps us to
          know how many people are interested in the event which means we can
          encourage more residents to take part as Trail Sites. You can be a
          Hunter from anywhere and lots of people come from many miles and
          neighbouring towns.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>Be a Trail Host</h3>
        <p>
          Just as we need Hunters we also need stops for them to visit. If you
          are lucky enough to live in the area covered by this trail and you are
          free on the day we would love you to register as a host location on
          the trail map; it's really easy to do: Just fill in the form below,
          choosing to be a Host, enter your postcode, set your map location and
          then complete the payment details on the next screen. Host locations
          work best when they are clustered together, so encourage your
          neighbours to take part too. This will likely attrach more Hunters on
          the day. There are a few legal limitations as to what you can sell and
          these can be found in the Files section on the facebook group.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>
          If you have already registered you can also log in to your account by
          using the form above
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import EventMap from "@/components/EventMap.vue";
import EventRegistrationForm from "@/components/EventRegistrationForm";

export default {
  name: "EventRegistrationBlurb",
  components: {
    EventMap,
    EventRegistrationForm,
  },
  methods: {
    ...mapGetters(["getActiveEvent"]),
    ...mapActions(["GetEventBySlug", "CheckParticipantByEmail"]),
  },
  computed: {
    ...mapState(["ActiveEvent"]),
    facebookLink() {
      return `https://facebook.com/events/${this.ActiveEvent.FacebookEventNumber}`;
    },
  },
};
</script>


