<template>
  <div>
    <b-list-group v-if="getEvents.length > 0">
      <EventListItem
        v-for="(Event, idx) in getEvents"
        :key="Event.EventID"
        :Event="Event"
        :idx="idx"
      ></EventListItem>
    </b-list-group>
    <!-- <hr />
    <b-card-group deck>
      <EventListCard
        v-for="(Event, idx) in getEvents"
        :key="Event.EventID"
        :Event="Event"
        :idx="idx"
      ></EventListCard>
    </b-card-group> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EventListItem from "@/components/EventListItem.vue";
import EventListCard from "@/components/EventListCard.vue";

export default {
  name: "EventList",
  components: {
    EventListItem,
    EventListCard,
  },
  computed: {
    // ...mapState(['Events'])
    ...mapGetters(["getEvents"]),
  },
};
</script>

<style></style>
