<template>
  <div>
    <b-row>
      <b-col>
        <h2>Upcoming Trails:</h2>
        <EventList />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <HomePageBlurb />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import HomePageBlurb from "../components/HomePageBlurb..vue";
import EventList from "../components/EventList.vue";

export default {
  name: "Home",
  components: {
    HomePageBlurb,
    EventList,
  },
  methods: {
    ...mapActions(["GetNextEvents"]),
  },
  computed: {
    ...mapState(["Events"]),
  },
  async created() {
    if (this.Events.length === 0) {
      await this.GetNextEvents();
    }
  },
};
</script>
