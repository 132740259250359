<template>
  <b-link :to="EventLink(Event)">
    <b-list-group-item
      :variant="idx % 2 == 1 ? 'primary' : 'info'"
      :text-variant="idx % 2 == 0 ? 'primary' : 'info'"
      button
    >
      <b-row>
        <b-col>
          {{ Event.EventName }}
        </b-col>
        <b-col text-align="right">
          {{ Event.EventDate }}
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-link>
</template>

<script>
export default {
  name: "EventListItem",
  props: {
    Event: Object,
    idx: Number,
  },
  methods: {
    EventLink(Event) {
      return "Event/" + Event.EventSlug;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
