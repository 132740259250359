<template>
  <div class="about">
    <Events/>
  </div>
</template>

<script>
import Events from '@/components/Events.vue'

export default {
  name: 'Home',
  components: {
    Events
  }
}
</script>
