<script>
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import 'ol/ol.css'
import {defaults as defaultInteractions} from 'ol/interaction'
import {defaults as defaultControls} from 'ol/control'

import * as turf from "@turf/turf";

export default {
  name: 'Scratch',
  components: {},
  props:{},
  data(){
    return{
      map:null,
      // map:{
      //   layers:null,
      //   view: null
      // },
      wards:[]
    }
  },
  mounted() {
   const  map = new Map({
      target: this.$refs['map-root'],
      controls: defaultControls(),
      interactions: defaultInteractions({
        doubleClickZoom: false,
      }),
      layers:[
        new TileLayer({
          source: new OSM()
        })
      ],
      view: new View({
        zoom:10,
        maxZoom:15,
        minZoom:7,
        center: this.convertToMercator(52.29525225869148,-2.3723602294921853),
        projection: 'EPSG:3857',
        extent: [-783938.1621,6420710.3760,217692.6566,8113331.9303], /* limits the map to UK mainland only*/
        constrainResolution: true
      })
    })
     this.xmap = map
  },
  // watch:{
  //   map(){
  //     console.log(map)
  //     //this.Xmap.zoom = this.view.getZoom()
  //   }
  // },
  methods:{
     convertToMercator(lat, long) {
      const x = turf.toMercator([long, lat]);
      return x;
    },
    handleDblClick(e){
      console.log('got doubleclick')
      e.preventDefault()
    }
  }
}
 </script>

<template>
    <b-row >
      <b-col 
        
        :cols="8"
      >
      <b-container
       ref="map-root"
       @dblclick="handleDblClick"
      >
      

      </b-container>
      </b-col>
      <b-col 
        ref="info"
        :cols="4">
        <b-row>
          <b-col cols="12">
            <h4>Current Zoom: </h4>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
</template>