<template>
  <b-container>
    <!-- <b-form
    @submit.prevent="formSubmit"
    inline
    > -->
    <!-- <b-row class="justify-content-md-center">
      <b-col cols="12"> -->
    <b-form>
      <b-card bg-variant="light">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Please enter and check your Email Address, then choose Host or Hunter"
              label-for="email"
            >
              <!-- Email and validation-->
              <b-form-input
                id="email"
                v-model="form.emailAddress"
                type="email"
                placeholder="Enter your email address"
                required
                @blur="validateEmail"
                content-cols="2"
              >
              </b-form-input>
              <div v-if="participantType === 'Known Email'">
                Welcome back, you are not currently registered for this event
              </div>
              <div v-if="participantType === 'Buyer'">
                Welcome back, you are registered for this event as a Trail Hunter
              </div>
              <div v-if="participantType === 'Seller'">
                Welcome back, you are already registered as a seller for this event as a
                Trail Host. Please continue if you want to add another host location.
                <b-form-input
                  id="passcode"
                  v-model="form.passcode"
                  type="password"
                  @blur="validatePasscode"
                >
                </b-form-input>
                <b-button
                  variant="info"
                  block
                  :disabled="!canRequestPassword"
                  @click="resendPasscode"
                  >Forgot Passcode
                </b-button>
              </div>
            </b-form-group>
            <!-- </b-col> -->
            <!-- <b-col cols="4"> -->
            <b-button
              v-if="Object.keys(ActiveUser).length === 0"
              variant="info"
              block
              :disabled="!isValidEmail(form.emailAddress)"
            >
              Check email
            </b-button>
          </b-col>
        </b-row>
        <!-- </b-card> 
    </b-form> -->
        <!-- End of Email and Validation -->
        <!--<hr />
    <form>
      <b-card bg-variant="light"> -->
        <b-row>
          <b-col cols="12">
            <b-form-group label="I would like to join the trail as a ">
              <!-- Host or Hunter-->
              <b-form-radio-group
                name="radio-participantType"
                v-model="form.participantType"
                button-variant="outline-primary"
                buttons
                :disabled="Object.keys(ActiveUser).length === 0"
              >
                <b-form-radio value="Seller" v-b-modal.modal-trail-host
                  >Trail Host</b-form-radio
                >
                <b-form-radio value="Buyer" v-b-modal.modal-trail-hunter
                  >Trail Hunter</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
    <hr />
    <!-- BUYER -->
    <b-modal id="modal-trail-hunter" title="Trail Hunter" hide-footer hide-header-close>
      <b-form v-if="form.participantType == 'Buyer'">
        <b-card bg-variant="light" name="hunterCard">
          <!-- <b-row>
              <b-col cols="12">
                <h1>Trail Hunter!</h1> -->
          <p>
            All we need to sign you up is your Email address and we will send you a copy
            of the trail map when it is available.
          </p>
          <p>
            If you would like to be kept informed about future trails, please enter your
            post code and the distance you are prepared to travel and we will keep you
            updated.
          </p>
          <!-- </b-col>
            </b-row>
            <b-row> 
              <b-col cols="12" >-->
          <b-form-group
            :label="'Tell Me About Trails within ' + form.hunterDistance + ' Miles'"
            label-for="HunterTellMe"
            class="mb-0"
            label-align="left"
          >
            <b-form-checkbox
              name="HunterTellMe"
              id="HunterTellMe"
              v-model="form.hunterTellMe"
              :checked="form.hunterTellMe"
              switch
              size="lg"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- <b-row>
                  <b-col cols="12"> -->
          <b-form-group>
            <b-input
              type="range"
              class="form-range"
              name="HunterDistance"
              id="HunterDistance"
              v-model="form.hunterDistance"
              mix="10"
              max="250"
              step="10"
            >
            </b-input>
          </b-form-group>
          <!-- </b-col>
                  <b-col cols="12"> -->
          <b-form-group label-for="HunterPostcode">
            <b-input
              type="text"
              name="HunterPostcode"
              id="HunterPostcode"
              placeholder="Enter your postcode"
              v-model="form.hunterPostcode"
            >
            </b-input>
          </b-form-group>
          <!-- </b-col>
                </b-row>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
            <b-row>
              <b-col cols="12"> -->
          <b-button block variant="info" @click="registerBuyer"> Sign Me Up! </b-button>
          <!-- </b-col>
            </b-row> -->
        </b-card>
      </b-form>
    </b-modal>
    <!-- SELLER -->
    <b-modal id="modal-trail-host" title="Trail Host" hide-footer hide-header-close>
      <!-- <b-card bg-variant="light" name="hostCard"> -->
      <!-- <h1>Host</h1> -->
      <!-- <b-row>
              <b-col> -->
      <p class="text-primary">
        Enter your address and postcode, click the Find on Map to center on your address.
        If the pin is not in quite the right place , or you are holding your stall
        elsewhere, use the arrow buttons to move the pin, Zoom in for more accurate
        control of the pin placement
      </p>
      <!-- </b-col>
            </b-row> -->
      <!-- <b-row align-content="center" id="address_and_map">
              <b-col cols="12" id="address"> -->
      <b-form-group>
        <b-input
          id="hostAddress"
          name="hostAddress"
          v-model="form.hostAddress"
          placeholder="123 Your Steet, Town"
        ></b-input>
        <b-input
          id="hostPostcode"
          name="hostPostcode"
          v-model="form.hostPostcode"
          placeholder="PO57 0DE"
        >
        </b-input>
        <b-button
          block
          variant="info"
          @click.prevent="findOnMap"
          :disabled="form.hostAddress.length < 6 || form.hostPostcode.length < 3"
          >Find on Map</b-button
        >
      </b-form-group>
      <!-- </b-col>
              <b-col 
                cols="12" 
                id="map_wrapper">
                <b-row> 
                  <b-col cols="12" class="text-center" >-->
      <vl-map
        :load-tiles-while-animating="true"
        :load-tiles-while-interacting="true"
        style="height: 360px; width: 100%"
      >
        <vl-view
          :zoom.sync="map.zoom"
          :center="map.center"
          :rotation.sync="map.rotation"
        ></vl-view>
        <vl-layer-tile id="osm">
          <vl-source-osm></vl-source-osm>
        </vl-layer-tile>
        <vl-feature draggable>
          <vl-geom-point :coordinates="pinLocation"> </vl-geom-point>
          <vl-style-box v-show="form.hostPostcodeLat != null">
            <!-- <vl-style-icon 
                    :src="require ('@/assets/blue-pin.png')" 
                    :anchor="[.5, 1]" 
                    :scale="0.15"
                  ></vl-style-icon> -->
            <vl-style-circle :radius="5">
              <vl-style-fill color="white"></vl-style-fill>
              <vl-style-stroke color="red"></vl-style-stroke>
            </vl-style-circle>
          </vl-style-box>
          <vl-style-box v-show="form.hostPostcodeLat != null">
            <vl-style-icon
              :src="require('@/assets/blue-pin.png')"
              :anchor="[0.43, 0.78]"
              :scale="0.08"
            ></vl-style-icon>
          </vl-style-box>
        </vl-feature>
      </vl-map>
      <!-- </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-row id="pin_nav_wrapper"> -->
      <!-- <b-col></b-col>
                      <b-col class="text-center"> -->
      <b-btn block variant="success" @click="moveUp" :disabled="!isFindAddressActivated">
        Up
      </b-btn>
      <!-- </b-col>
                      <b-col></b-col>
                    </b-row>
                    <b-row> -->
      <!-- <b-col class="text-right"> -->
      <b-btn
        block
        variant="success"
        @click="moveLeft"
        :disabled="!isFindAddressActivated"
      >
        Left
      </b-btn>
      <!-- </b-col>
                      <b-col></b-col>
                      <b-col class="text-left"> -->
      <b-btn
        block
        variant="success"
        @click="moveRight"
        :disabled="!isFindAddressActivated"
      >
        Right
      </b-btn>
      <!-- </b-col>
                    </b-row>
                    <b-row>
                      <b-col></b-col> -->
      <!-- <b-col class="text-center"
                        > -->
      <b-btn
        block
        variant="success"
        @click="moveDown"
        :disabled="!isFindAddressActivated"
      >
        Down
      </b-btn>
      <!-- </b-col>
                      <b-col></b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row> -->
      <!-- end of address and map wrapper -->

      <!-- <b-row>
              <b-col> -->
      <b-form-group label="Additional Details" label-for="hostAdditionalDetails">
        <b-textarea
          id="hostAdditionalDetails"
          name="hostAdditionalDetails"
          v-model="form.hostAdditionalDetails"
          rows="5"
          placeholder="Use this to describe how to find your trail stop"
        >
        </b-textarea>
      </b-form-group>
      <!-- </b-col>
            </b-row> -->
      <!-- </b-card> -->

      <!-- <b-row v-if="form.participantType == 'Seller'">
          <b-col cols=12> -->

      <!-- </b-col> -->

      <!-- button only active if we have done find address and not done Register-->
      <b-button
        block
        variant="info"
        @click="proceedToPayment"
        :disabled="!isFindAddressActivated && !isRegistrationSubmitted"
        >Proceed to Payment</b-button
      >
      <!-- </b-row> -->
      <!-- </b-col>
    </b-row> -->
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as turf from "@turf/turf";
import stripe from "stripe";

export default {
  name: "EventRegistrationForm",
  components: {
    Map,
  },
  data() {
    return {
      form: {
        emailAddress: null,
        passcode: null,
        participantType: null,
        hunterTellMe: true,
        hunterDistance: 20,
        hunterPostcode: null,
        hostAddress: "",
        hostPostcode: "",
        hostPostcodeLat: null,
        hostPostcodeLong: null,
        hostChosenLat: null,
        hostChosenLong: null,
        hostDisplayAddress: null,
        hostType: null,
        hostAdditionalDetails: null,
      },
      isPasswordRequested: false,
      isFindAddressActivated: false,
      isRegistrationSubmitted: false,
      map: {
        zoom: 15,
        long: this.ActiveEvent ? this.ActiveEvent.long : 0,
        lat: this.ActiveEvent ? this.ActiveEvent.lat : 0,
        center: [0, 0],
        rotation: 0,
        geolocPosition: undefined,
      },
    };
  },
  methods: {
    ...mapActions([
      "CheckParticipantByEmail",
      "CheckPasscode",
      "EmailRequestPasscode",
      "RegisterSeller",
      "RegisterBuyer",
      "LaunchCheckout",
    ]),
    isValidEmail(email) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (email === null) {
        return false;
      }
      if (email.match(mailformat)) {
        // alert("Valid email address!");
        // document.form1.text1.focus();
        return true;
      } else {
        // alert("You have entered an invalid email address!");
        // document.form1.text1.focus();
        return false;
      }
    },
    async registerBuyer() {
      const payload = {
        eventID: this.ActiveEvent.ID,
        participantType: "Buyer",
        price: "null",
        emailAddress: this.form.emailAddress,
        addressLine1: "",
        postcode: this.form.hunterPostcode,
        lat: "null",
        long: "null",
        tellMe: this.form.hunterTellMe,
        miles: this.form.hunterDistance,
      };
      // const response = await this.RegisterBuyer(payload)
      // console.log("Registration Response: ", response)
      await this.RegisterBuyer(payload);
      //console.log(x)
      this.$router.push({ name: "Home" });
    },
    async validateEmail() {
      console.log("in validateEmail", this.form.emailAddress);
      if (this.isValidEmail(this.form.emailAddress) === false) {
        return;
      }
      const payload = {
        emailAddress: this.form.emailAddress,
        eventID: this.ActiveEvent.ID,
      };
      await this.CheckParticipantByEmail(payload);
    },
    async validatePasscode() {
      const payload = {
        participantID: this.ActiveUser.ParticipantID,
        passcode: this.form.passcode,
      };
      const toast = await this.CheckPasscode(payload);
      // TODO: Redirect to User Portal
    },
    async resendPasscode() {
      // Ping the API with the form.emailAddress and email the relevant passcode
      const toast = await this.EmailRequestPasscode({
        participantID: this.ActiveUser.ParticipantID,
      });
      this.isPasswordRequested = toast;
      // TODO: toast message that request has been sent
    },
    convertToMercator(lat, long) {
      const x = turf.toMercator([long, lat]);
      return x;
    },
    findOnMap() {
      const baseURL = process.env.VUE_APP_API_URL;
      const url = `${baseURL}searchAddress`;
      const data = {
        hostAddress: this.form.hostAddress,
        hostPostcode: this.form.hostPostcode,
      };
      axios.post(url, data).then((res) => {
        const data = res.data[0];
        this.form.hostPostcodeLat = parseFloat(data.lat);
        this.form.hostPostcodeLong = parseFloat(data.long);
        this.form.hostChosenLat = parseFloat(data.lat);
        this.form.hostChosenLong = parseFloat(data.long);
        this.form.hostDisplayAddress = data.displayName;
        this.form.hostType = data.type;
        this.map.lat = parseFloat(data.lat);
        this.map.long = parseFloat(data.long);
        this.map.center = this.convertToMercator(
          parseFloat(data.lat),
          parseFloat(data.long)
        );
        this.map.zoom = 18;
        this.isFindAddressActivated = true;
      });
    },
    moveUp() {
      this.form.hostChosenLat = this.form.hostChosenLat + this.stepSize;
    },
    moveDown() {
      this.form.hostChosenLat = this.form.hostChosenLat - this.stepSize;
    },
    moveLeft() {
      this.form.hostChosenLong = this.form.hostChosenLong - this.stepSize;
    },
    moveRight() {
      this.form.hostChosenLong = this.form.hostChosenLong + this.stepSize;
    },
    async proceedToPayment() {
      // TODO: register as a pending seller and then move to Stripe processing
      const payload = {
        eventID: this.ActiveEvent.ID,
        participantType: "Seller",
        price: this.ActiveEvent.price ? this.ActiveEvent.price : 4.99,
        emailAddress: this.form.emailAddress,
        addressLine1: this.form.hostAddress,
        postcode: this.form.hostPostcode,
        lat: this.form.hostChosenLat,
        long: this.form.hostChosenLong,
        tellMe: "false", // this.form.hunterTellMe,
        miles: 0, // this.form.hunterDistance,
      };
      const res = await this.RegisterSeller(payload).then(res);
      this.isRegistrationSubmitted = true;
      const seller = res[0];
      //TODO: when we get the response back redirect to payment
      let stripePayload = {
        eventID: seller.EventID, //this.ActiveEvent.ID,
        eventName: this.ActiveEvent.TrailName,
        participantType: "Seller", //this.form.participantType,
        subscriptionCode: seller.LoginCode, //this.subscriptionCode(),
        price: this.ActiveEvent.price ? this.ActiveEvent.price : 4.99, // Stripe requires this in pence but we uplift it in the API
        emailAddress: this.form.emailAddress,
        address1: this.form.hostAaddress,
        address2: null, //this.form.address2,
        address3: null, //this.form.address3,
        town: null, //this.form.town,
        county: null, //this.form.county,
        postCode: this.form.hostPostcode,
        tellMe: null, //this.form.tellMe,
        distance: null, //this.form.distance
        eventParticipantID: seller.ID,
      };
      this.LaunchCheckout(stripePayload);
    },
    formSubmit() {
      alert("form submitted");
    },
  },
  computed: {
    ...mapState(["ActiveEvent", "ActiveUser", "isUserLoggedIn", "stripe"]),
    canRequestPassword() {
      if (
        this.isUserLoggedIn ||
        this.isPasswordRequested ||
        this.ActiveUser.ID === "undefined"
      ) {
        return false;
      } else {
        return true;
      }
    },
    participantType() {
      if (this.ActiveUser) {
        if ("ParticipantID" in this.ActiveUser === false) return "Not Set";
        if (this.ActiveUser.ParticipantID === -1) return "New Email";
        if ("ParticipantType" in this.ActiveUser === false) return "Known Email";
        return this.ActiveUser.ParticipantType;
      }
      return "No Active User";
    },
    pinLocation() {
      return this.convertToMercator(
        this.form.hostChosenLat ? this.form.hostChosenLat : this.ActiveEvent.lat,
        this.form.hostChosenLong ? this.form.hostChosenLong : this.ActiveEvent.long
      );
    },
    stepSize() {
      return Math.max(
        (0.00005 *
          ((20 - Math.floor(this.map.zoom)) * (20 - Math.floor(this.map.zoom)))) /
          2,
        0.00002
      );
    },
  },
  // mounted (){
  //   this.$nextTick(function () {
  //     // Code that will run only after the
  //     // entire view has been rendered
  //     console.log ('mounted the registration form: ', this.ActiveEvent)
  //   })
  // }
  watch: {
    ActiveEvent: function () {
      this.map.center = this.convertToMercator(
        this.ActiveEvent.lat,
        this.ActiveEvent.long
      );
    },
  },
};
</script>

<style></style>
