<template>
  <div style="width: 100%; height: 50vh">
    <vl-map
      v-if="ActiveEvent !== undefined"
      :load-tiles-while-animating="true"
      :load-tiles-while-interacting="true"
      style="height: 90%; width: 100%"
      data-projection="EPSG:4326"
    >
      <vl-view
        :zoom.sync="map.zoom"
        :center="centerOfMap"
        :rotation.sync="map.rotation"
      ></vl-view>
      <vl-layer-tile id="osm">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>

      <vl-feature v-for="S in ActiveEvent.KMLShapes" :key="S.KMLName">
        <vl-geom-multi-polygon :id="TEST2 + S.KMLName" :coordinates="[geometry(S)]">
        </vl-geom-multi-polygon>
        <vl-style-box>
          <vl-style-fill :color="[173, 13, 106, 0.4]"></vl-style-fill>
          <vl-style-stroke :color="[173, 13, 106, 0.9]" :width="0.5"></vl-style-stroke>
        </vl-style-box>
      </vl-feature>

      <vl-feature v-for="(pin, index) in turfPins.features" :key="index">
        <vl-geom-point :coordinates="pin.geometry.coordinates"> </vl-geom-point>
        <vl-style-box>
          <vl-style-circle :radius="5">
            <vl-style-fill color="white"></vl-style-fill>
            <vl-style-stroke color="red"></vl-style-stroke>
          </vl-style-circle>
          <!-- <vl-style-text :text="index"></vl-style-text> -->
        </vl-style-box>
        <vl-style>
          <vl-style-icon
            :src="require('@/assets/purple-pin.png')"
            :anchor="[0.25, 0.9]"
            :scale="0.15"
          ></vl-style-icon>
        </vl-style>
      </vl-feature>
    </vl-map>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as turf from "@turf/turf";

export default {
  name: "EventMap",
  components: {
    Map,
  },
  data() {
    return {
      map: {
        zoom: 12,
        rotation: 0,
      },
    };
  },
  methods: {
    convertToMercator(lat, long) {
      const x = turf.toMercator([long, lat]);
      return x;
    },
    geometry(KML) {
      /*This needs to be a bid cleverer: If it is a Multipolygon it needs to iterate through the <Poluygons>*/
      //console.log("in geometry")
      const KMLString = KML.KMLShape;
      //console.log("KML Name: ", KML.KMLName)
      //console.log("KML String: ", KMLString)

      const KMLArray = KMLString.replace("<MultiGeometry>", "")
        .replace("</MultiGeometry>", "")
        .split("<Polygon>")
        .filter((x) => x !== "")
        .map((x) =>
          x
            .replace("<outerBoundaryIs><LinearRing><coordinates>", "")
            .replace("</Polygon></coordinates></LinearRing></outerBoundaryIs>", "")
            .split(" ")
            .map((c) => c.split(","))
            .map((c) => {
              return [parseFloat(c[0]), parseFloat(c[1])];
            })
            .map((c) => {
              return turf.toWgs84(this.convertToMercator(c[1], c[0]));
            })
        );

      // console.log("KMLArray: ", KMLArray)

      // const coordinates_string =
      // KMLString
      //   .replace('<Polygon><outerBoundaryIs><LinearRing><coordinates>','')
      //   .replace('</coordinates></LinearRing></outerBoundaryIs></Polygon>')
      //   .replace('<MultiGeometry>','')
      //   .replace('</MultiGeometry>','')
      // console.log("Cleaned String: ", coordinates_string)
      // const coordinates_array = coordinates_string.split(' ')
      //   .map(c=> c.split(','))
      //   .map(c=>{
      //    return [parseFloat(c[0]),parseFloat(c[1])]
      //   })
      //   .map(c=> {
      //   return this.convertToMercator(c[0],c[1])
      //   })
      // console.log("Coordinates for " + KML.KMLName  + ": ", [coordinates_array])
      // return [coordinates_array]
      return KMLArray;
    },
  },
  computed: {
    ...mapState(["ActiveEvent"]),
    turfPins() {
      const x = [
        [this.ActiveEvent.lat, this.ActiveEvent.long],
        ...this.ActiveEvent.SellersList.map((p) => [p.Latitude, p.Longlitude]),
      ];
      const y = x.filter(
        (p) => !(p[0] == null || p[1] == null)
      ); /* strip out any null values*/
      const z = y.map((p) => turf.toWgs84(this.convertToMercator(p[0], p[1])));
      return turf.points(z);
    },
    centerOfMap() {
      //var center = turf.toWgs84(turf.center(this.turfPins));
      var center = turf.center(this.turfPins);
      return center.geometry.coordinates;
    },
  },
};
</script>
