import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Home.vue'
// mport About from '@/pages/About.vue'
import Trails from '@/pages/Trails.vue'
import Groups from '@/pages/Groups.vue'
import Events from '@/pages/Events.vue'
import EventDetail from  '@/pages/EventDetail.vue'
import Scratch from '@/pages/Scratch.vue'
import Scratch2 from '@/pages/Scratch2.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/pages/About.vue')
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups
  },
  {
    path: '/trails',
    name: 'Trails',
    component: Trails
  },
  {
    path: '/Events',
    name: 'Events',
    component: Events
  },
  {
    path: '/Event/:slug',
    name: 'EventDetail',
    component: EventDetail
  },
  {
    path: '/Scratch',
    name: 'Scratch',
    component: Scratch
  },
  {
    path: '/Scratch2',
    name: 'Scratch2',
    component: Scratch2
  },
  {
    path:'*',
    redirect:'/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
