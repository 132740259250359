<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>Groups!</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols=12>
        <b-list-group>
          <b-list-group-item
          v-for="Group in FBGroups" :key="Group.name"
          >
          <b-card>
            <b-card-section>
              {{Group.GroupName}}
            </b-card-section>
            <b-card-section>
              <b-button>
                <b-link :href="facebooklink(Group)" target="_blank">
                Got to Facebook Group
              </b-link>
              </b-button>
              <b-button
              info
              >
              {{Group}}
              </b-button>
            </b-card-section>
          </b-card>

          </b-list-group-item>
        </b-list-group>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
// import Groups from "../components/groups.vue'

export default {
  name:'Groups',
  // components:{
  //   Groups
  // },
  methods: {
    ...mapActions(['FetchFacebookGroups'])
    ,facebooklink(Group) {
      let link = ''
      if(Group.FacebookGroupNumber===null) {
        link = Group.FacebookGroupName
      } else {
        link = Group.FacebookGroupNumber.toString()
      } 
      return '//facebook.com/groups/' + link
    }
  },
  computed: {
    ...mapState(['FBGroups'])
    
  },
  async created(){
    if (this.FBGroups.length === 0) {
      await this.FetchFacebookGroups()
    }
  }
}
</script>
